/* .mainContainer {
  width: 100vw;
  height: 13vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2d2d27a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(5px);
} */

/* #img {
  position: absolute;
  left: 1em;
  object-fit: cover;
} */

/* .navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  width: fit-content;
} */

.devider {
  height: 2.5vh;
  border-left: 0.1em solid var(--primary-text-color);
}

/* #navText {
  color: var(--primary-text-color);
  font-weight: bold;
  font-size: 1.2em;
} */
