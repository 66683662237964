.container {
  width: 100%;
  height: 0.1em;
  display: flex;
  flex-direction: row;
}

.devider {
  width: 50%;
  background: linear-gradient(0.25turn, #ffffff00, var(--secondary-color));
}

.devider2 {
  width: 50%;
  background: linear-gradient(-0.25turn, #ffffff00, var(--secondary-color));
}
